import { useState, useEffect, useRef,scrollContainerRefs } from "react";
import Grid from '@mui/material/Grid'
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from 'react';
import config , { updateDomain } from '../config';
import Button from '@mui/material/Button';
import { ReactSession } from 'react-client-session';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import { SnackbarProvider, useSnackbar } from 'notistack';




const style = {
  position: 'absolute',
  top: '29%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 660,
  bgcolor: 'background.paper',

  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
//  top: '50%',
const ActionComponent = ({ open, onClose, data , sendclass,docketBump,ItemsId,itemsStatus,LastDocketStatus, AllItemIds,isFos ,fosStatus,itemData}) => {
    
  const getBtnConfig = ReactSession.get("btnConfig")
  const currentStatus = fosStatus;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);
  const [formData, setFormData] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

    const [sendclasse, setSendclasse] = React.useState("");
    const currentFontVal = ReactSession.get('currentFont');
     const handleContentClick = (e) => {
    e.stopPropagation(); // Stop the event from bubbling up to the modal's onClose handler
  };



  const getVisibleButtons = () => {
    const buttons = getBtnConfig[isFos] || [];
    
    return buttons.filter(btn => {
      if (btn.depend_field && itemData) {
        // Get the current status from itemData using the depend_field
        const currentStatus = itemData[btn.depend_field];
        // Check if the button should be shown for this status
        return btn.show.includes(currentStatus);
      }
      return false; // Don't show buttons without depend_field
    });
  };


  // Get visible buttons
  const visibleButtons = getVisibleButtons();


  const handleButtonClick = (button) => {
    if (button.fields && button.fields.length > 0) {
      // Initialize form data with empty values
      const initialFormData = {};
      button.fields.forEach(field => {
        console.log(field.field_name);
        initialFormData[field.field_name] = '';
      });
      setFormData(initialFormData);
      setSelectedButton(button);
      setIsDialogOpen(true);
    } else {
      // Handle buttons without fields

      //console.log('Button clicked:', button.name, 'Value:', button.value);
      setLoading(true);

      // Prepare payload with status and form data
      const payload = {
        status: button.value,
        // field_name: selectedButton.field_name,
        is_online : isFos,
        id : ItemsId,
       
      };

      submitToApi(payload);
     
      // showSnackbar('Data submitted successfully', 'success');
      enqueueSnackbar('Data submitted successfully', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 10000,
      });
      
    }
  };

  // Function to handle form submission
   // Function to handle form submission
   const handleSubmit = async () => {
    // Validate required fields
    const isValid = selectedButton.fields.every(field => 
      !field.required || formData[field.field_name]
    );

    if (!isValid) {
      showSnackbar('Please fill in all required fields', 'error');
      return;
    }

    try {
      setLoading(true);

      // Prepare payload with status and form data
      const payload = {
        status: selectedButton.value,
        // field_name: selectedButton.field_name,
        is_online : isFos,
        id : ItemsId,
        ...formData
      };

      

      await submitToApi(payload);
      enqueueSnackbar('Data submitted successfully', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 10000,
      });
      //showSnackbar('Data submitted successfully', 'success');
      handleClose();
    } catch (error) {
      console.error('Error submitting form:', error);
      showSnackbar('Failed to submit data', 'error');
    } finally {
      setLoading(false);
    }
  };

    // Function to make API call
    const submitToApi = async (payload) => {
      const getToken = ReactSession.get("authToken")
      axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`;
        // Set headers
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken}`
        };
      try {
        // Replace 'YOUR_API_ENDPOINT' with your actual API endpoint
        const url = config().apiUrl + "button-submit";
        
        const response = await axios.post(url, payload, { headers });
        console.log('API Response:', response.data);
        onClose();
        return response.data;
      } catch (error) {
        console.error('API Error:', error);
        throw error;
      }
    };

  // Function to handle input change
  const handleInputChange = (fieldName, value) => {
    setFormData(prev => ({
      ...prev,
      [fieldName]: value
    }));
  };

  // Function to handle dialog close
  const handleClose = () => {
    setIsDialogOpen(false);
    setSelectedButton(null);
    setFormData({});
  };



  // Function to show snackbar
  const showSnackbar = (message, severity) => {
    setSnackbar({
      open: true,
      message,
      severity
    });
  };

  // Function to handle snackbar close
  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const getButtonClass = (buttonName) => {
    return "bg-yellow-400 border-2 border-black text-black font-medium px-6 py-2 hover:bg-yellow-500";
  };
   
    
  
    
  return (
    <div className="w-full">
    <Modal
      className="backdrop-blur"
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{
        style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
      }}
    >
      <Box 
        sx={style}  
        className={`${currentFontVal} modalcls absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-xl p-6 w-full max-w-2xl`} 
        onClick={handleContentClick}
      >
        <div className={sendclass}>
          <Grid item xs={12} md={12}>
            <div id='stats'>
              <div className='colaction'>
                Order Number
                <Typography variant='h6' sx={{ mb: 6 }}>
                  {data}
                </Typography>
              </div>
            </div>
          </Grid>
        </div>
        
        <div id="modal-modal-description" className="mt-4">
          {/* Centered Bump Button */}
          <div className="flex justify-center mb-6">
            <Button 
              onClick={() => { 
                docketBump(ItemsId, itemsStatus, LastDocketStatus, AllItemIds, isFos); 
                onClose(); 
              }} 
              variant="contained"
              className="w-32 h-10"
            >
              {itemsStatus === 5 ? "Undo Bump" : "Bump"}
            </Button>
          </div>

          {/* Action Buttons Grid with Text Overflow Handling */}
          <div className="grid grid-cols-2 gap-4 sm:grid-cols-3">
            {visibleButtons.map((button, index) => (
              <Button
                key={index}
                onClick={() => handleButtonClick(button)}
                variant="outlined"
                className="w-full h-10 normal-case rounded-lg bg-sky-50 hover:bg-sky-100 text-sky-700 border-sky-200"
              >
                <span className="block px-2 text-sm truncate">
                  {button.name}
                </span>
              </Button>
            ))}
          </div>

          <Dialog 
            open={isDialogOpen} 
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
          >
            {selectedButton && (
              <>
                <DialogTitle>
                  <Typography variant="h6">{selectedButton.name}</Typography>
                </DialogTitle>
                
                <DialogContent>
                  <Stack spacing={3} sx={{ mt: 2 }}>
                    {selectedButton.fields.map((field, index) => (
                      <TextField
                        key={index}
                        label={field.name}
                        type={field.type}
                        value={formData[field.field_name] || ''}
                        onChange={(e) => handleInputChange(field.field_name, e.target.value)}
                        required={field.required}
                        fullWidth
                        variant="outlined"
                        error={field.required && !formData[field.field_name]}
                        helperText={field.required && !formData[field.field_name] ? 'This field is required' : ''}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={loading}
                      />
                    ))}
                  </Stack>
                </DialogContent>

                <DialogActions>
                  <Button onClick={handleClose} color="inherit" disabled={loading}>
                    Cancel
                  </Button>
                  <Button 
                    onClick={handleSubmit} 
                    variant="contained" 
                    color="primary"
                    disabled={loading}
                  >
                    {loading ? <CircularProgress size={24} /> : 'Submit'}
                  </Button>
                </DialogActions>
              </>
            )}
          </Dialog>

          <Snackbar 
            open={snackbar.open} 
            autoHideDuration={6000} 
            onClose={handleSnackbarClose}
          >
            <Alert 
              onClose={handleSnackbarClose} 
              severity={snackbar.severity} 
              sx={{ width: '100%' }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        </div>
      </Box>
    </Modal>
  </div>

    );
 
};

export {ActionComponent} ;
