import React, { useState ,useEffect } from "react";
import useForm from "./useForm";
import validate from "./LoginFormValidationRules";
import { useNavigate  } from "react-router-dom";
import Grid from '@mui/material/Grid'
import './login.css';
import config , { updateDomain } from '../../config';
import {Box,List,Typography,useTheme,Button} from "@mui/material";

import axios from 'axios';
import { ReactSession } from 'react-client-session';

const Form = props => {
  const { values, errors, tagsScreen , tagsScreenValue , tagsScreenPreValue, handleChange, handleSubmit , handleChangelast, handleSubmitLast  } = useForm(
    login,
    validate
  );
  const [loggedIn, setLoggedIn] = useState(false);
  
  // const navigate = useNavigate();
  // State to store the selected items
  const [selectedItems, setSelectedItems] = useState(tagsScreenPreValue);
  // State to store the unique values of selected items
  const [uniqueItems, setUniqueItems] = useState([]);
  const [nextScreen, setNextScreen] = useState(false);
  const [checkedValues, setCheckedValues] = useState([]);
  const currentUserNameVars = ReactSession.get('kdsName');

  

  function login() {
    setLoggedIn(true);
    props.parentCallback(true);
    
    //return  navigate('/default');;
  }
  
  const handleCheckboxChange = (event) => {
    try {
      // Access the target element from the event
      const target = event.target;
      const { value, checked } = event.target;
      if (checked) {
        setCheckedValues((prevValues) => [...prevValues, value]);
      } else {
        setCheckedValues((prevValues) => prevValues.filter((val) => val !== value));
      }
      // Check if the target element exists and has a value
      if (target && target.value !== undefined) {
        const { value, checked } = target;

        // If the checkbox is checked, add the item to the selectedItems array
        if (checked) {
          setSelectedItems(prevItems => [...prevItems, value]);
          // Add the value to the uniqueItems array if it's not already present
          if (!uniqueItems.includes(value)) {
            setUniqueItems(prevItems => [...prevItems, value]);
          }
        } else {
          // If the checkbox is unchecked, remove the item from the selectedItems array
          setSelectedItems(prevItems => prevItems.filter(item => item !== value));
          // Remove the value from the uniqueItems array
          setUniqueItems(prevItems => prevItems.filter(item => item !== value));
        }
      } else {
        console.error("Target element or its value is undefined");
      }
    } catch (error) {
      console.error("Error handling checkbox change:", error);
    }
   
  };
  
  useEffect(() => {
    
    // Retrieve previously selected items from storage (e.g., local storage)
    const previouslySelectedItems =  tagsScreenPreValue;
  
      setSelectedItems(previouslySelectedItems);
      setUniqueItems([...new Set(previouslySelectedItems)]);
    
  }, [tagsScreenPreValue]);

  // Function to save selected items to storage when they change
  useEffect(() => {
    
    // Save selected items to storage (e.g., local storage)
    localStorage.setItem('selectedItems', JSON.stringify(tagsScreenPreValue));
  }, [selectedItems]);
  
  const onClickNext = async (data) => {
    console.log(ReactSession.get("domain"))
    setNextScreen(true);
    const array = selectedItems;
    ReactSession.set("selectedtags", selectedItems);
    const getToken = ReactSession.get("authToken")
    axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`;
      // Set headers
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken}`
      };
    const result = array.join(',');
    const url = config().apiUrl + "kitchen-device-tag-update";
    try {
      const response = await axios.post(url, {
        tags :result
     },{headers});

     ReactSession.set("currentTags", result);
    
   } catch (error) {
    if (error.response && error.response.status === 401) {
      // Handle 401 Unauthorized error
      ReactSession.set("currentTags", null);
      ReactSession.set("kdsName", null);
      ReactSession.set("authToken", null);
      ReactSession.set("userData", null);
      ReactSession.set("kdsName", null);
      ReactSession.set("userSetting", null);
      ReactSession.set("alltags", null);
      window.location.reload(); // Reload the page
    } else {
      // Handle other errors
      console.error(error);
    }
   }
  
  };

  const onClickPrevious = async (data) => {
    setNextScreen(false);
    const name = values.userName ? values.userName : ReactSession.get("kdsName");
    const url = config().apiUrl + "kds-device-update";
    const getToken = ReactSession.get("authToken")
    axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`;
      // Set headers
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken}`
      };
    try {
      const response = await axios.post(url, {
        name :name
     },{headers});

     ReactSession.set("kdsName", name);
    
   } catch (error) {
    if (error.response && error.response.status === 401) {
      // Handle 401 Unauthorized error
      ReactSession.set("currentTags", null);
      ReactSession.set("kdsName", null);
      ReactSession.set("authToken", null);
      ReactSession.set("userData", null);
      ReactSession.set("kdsName", null);
      ReactSession.set("userSetting", null);
      ReactSession.set("alltags", null);
      window.location.reload(); // Reload the page
    } else {
      // Handle other errors
      console.error(error);
    }
   }
  };

  const onClickSubmit = async(data) => {
    const name = values.userName ? values.userName : ReactSession.get("kdsName") ;
    ReactSession.set("kdsName", name);
    const url = config().apiUrl + "kds-device-update";
    const getToken = ReactSession.get("authToken")
    axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`;
      // Set headers
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken}`
      };
    try {
      const response = await axios.post(url, {
        name :name
     },{headers});
     window.location.reload();
    
   } catch (error) {
    if (error.response && error.response.status === 401) {
      // Handle 401 Unauthorized error
      ReactSession.set("currentTags", null);
      ReactSession.set("kdsName", null);
      ReactSession.set("authToken", null);
      ReactSession.set("userData", null);
      ReactSession.set("kdsName", null);
      ReactSession.set("userSetting", null);
      ReactSession.set("alltags", null);
      window.location.reload(); // Reload the page
    } else {
      // Handle other errors
      console.error(error);
    }
   }
    //
  };
   

  return (
    <div>
    
      {!tagsScreen && (
       <Box m="20px" className="">
         <div className="form-container outer">
              <div className="form-form">
                  <div className="form-form-wrap">
                      <div className="form-container">
                          <div className="form-content">
                              <div className="avatar avatar-xl">
                              <img alt="avatar"  src="https://pos.foodship.net/admin/uploads/foodshipbiglogo.png" className="rounded logoimg" />
                              </div>
                              <p className="tex-center margin" >Log in
                                  to KDS.</p>
                                  <form className="text-left form" onSubmit={handleSubmit} noValidate>
                                    
                                  <div className="field field-wrapper input">
                                      <label id="username-field"  className="label">Domain</label>
                                      <div className="control">
                                        <input
                                          autoComplete="off"
                                          className={`input form-control ${errors.pin && "is-danger"}`}
                                          type="text"
                                          name="domain"
                                          onChange={handleChange}
                                          value={values.domain || ""}
                                          required
                                        />
                                        {errors.domain && (
                                          <p className="help is-danger">{errors.domain}</p>
                                        )}
                                      </div>
                                    </div>

                                    <div className="field field-wrapper input">
                                      <label id="username-field"  className="label">Pin</label>
                                      <div className="control">
                                        <input
                                          autoComplete="off"
                                          className={`input form-control ${errors.pin && "is-danger"}`}
                                          type="pin"
                                          name="pin"
                                          onChange={handleChange}
                                          value={values.pin || ""}
                                          required
                                        />
                                        {errors.pin && (
                                          <p className="help is-danger">{errors.pin}</p>
                                        )}
                                      </div>
                                    </div>
                                    
                                    <div className="field d-sm-flex justify-content-between">
                                      <div className="control field-wrapper">
                                        <button
                                          type="submit"
                                          className="button btn btn-primary login-form-button ant-btn is-block is-info is-fullwidth"
                                        >
                                          Log In
                                        </button>
                                      </div>
                                    </div>
                                </form>
                              <p className="mb-0 signup-link">Powered By: <a href="https://foodship.com.au">
                                      Foodship</a></p>


                          </div>

                      </div>

                  </div>
              </div>
          </div>
        
       </Box>)}
       {tagsScreen && ( <div className="under">
       {!nextScreen && ( <div className="tagsScreen">
       <div className="form-container outer">
              <div className="form-form">
                  <div className="form-form-wrap">
                      <div className="form-container">
                          <div className="form-content card md:w-48">
                              <div className="avatar avatar-xl">
                             
                              </div>
                              
                                  <div className=" card-1">
                                  <div className="top">
                                  <div className="hiddenCB">
                                      <h3>Select Tags</h3>
                                     
                                       <div className="text-left form">
                                      
                                      {tagsScreenValue &&  tagsScreenValue.map((item, index) => {
                                          const matchedItem = tagsScreenPreValue.find(current => current == item.id)  ;
                                        
                                      return (
                                        <div className="mb-2 field-wrapper input checkdiv grey400">
                                         
                                        <input type="checkbox" className="le-checkbox"  value={item.id} onChange={handleCheckboxChange} checked={selectedItems.includes(matchedItem?matchedItem:item.id.toString())} id={`cb${index + 1}`} /><label className="labelCls" for={`cb${index + 1}`}>{item.name}</label>
                                        </div>
                                      )
                                      })}
                                      <form className="text-left">
                                        <div className="text-left form">
                                      <div className="field d-sm-flex justify-content-between">
                                      <div className="control field-wrapper">
                                        <button
                                          type="button"
                                          onClick={() => onClickNext()}
                                          className="button btn nextbutton tagselectionbutton btn-primary login-form-button ant-btn is-block is-info is-fullwidth"
                                        >
                                          Next
                                        </button>

                                      </div>
                                    </div> 
                                    </div>
                                    </form>      
                                      
                                      </div>
                  
                                        </div>
                                      </div>
                              
                                  </div>
                             


                          </div>

                      </div>

                  </div>
              </div>
          </div>
        </div>
        )}
        {nextScreen && ( <div className="nextScreen">
        <Box m="20px" className="">
        <div className="form-container outer">
              <div className="form-form">
                  <div className="form-form-wrap">
                      <div className="form-container">
                          <div className="form-content">
                              <div className="avatar avatar-xl">
                              
                              </div>
                              
                                  <form className="text-left form" onSubmit={handleSubmit} noValidate>
                                    <div className="field field-wrapper input">
                                      <label id="username-field"  className="label">Kitchen Display Name</label>
                                      <div className="control">
                                        
                                          <input
                                          autoComplete="off"
                                          className={`input form-control ${errors.userName && "is-danger"}`}
                                          type="text"
                                          name="userName"
                                          
                                          onChange={handleChange}
                                          value={values.userName || currentUserNameVars}
                                          required
                                        />
                                        {errors.userName && (
                                          <p className="help is-danger">{errors.userName}</p>
                                        )}
                                      </div>
                                    </div>
                                    
                                    <div className="field d-sm-flex justify-content-between">
                                      <div className="control field-wrapper">
                                        <button
                                          type="button"
                                          onClick={() =>  onClickSubmit()}
                                          className="leftbtn button usernamebutton btn btn-primary login-form-button ant-btn is-block is-info is-fullwidth"
                                        >
                                         
                                          Submit
                                        </button>
                                        <button
                                          type="button"
                                          onClick={() => onClickPrevious()}
                                          className="rightbtn button btn btn-primary usernamebutton login-form-button ant-btn is-block is-info is-fullwidth"
                                        >
                                           Previous
                                        </button>
                                      </div>
                                    </div>
                                   
                                </form>
                              


                          </div>

                      </div>

                  </div>
              </div>
          </div>
        </Box>
        </div>)}
       </div>
        )}
     </div>
     
  );
};

export default Form;
