import React, { useState, useEffect, useRef,scrollContainerRefs,useMemo} from 'react'
import { Footer, Navbar, Sidebar, ThemeSettings } from './components'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Pusher from 'pusher-js';
import ReactLoading from "react-loading";
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import Swal from 'sweetalert2';
import { FiSettings } from 'react-icons/fi'
import config , { updateDomain } from './config';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { TooltipComponent } from '@syncfusion/ej2-react-popups'
import { useStateContext } from './contexts/ContextProvider'
import { OrderListWhitOutTags , OrderList, DelayedList, Orders, Employees, Customers, Calendar, Kanban, Editor, ColorPicker, Line, Pie, StackedChart, Area, Bar, ColorMapping, Financial, Pyramid } from './pages'
import Form from "./pages/login";
import SettingComponent from "./pages/settings";
import TagScreen from "./pages/settings/tagscreen";
import FontScreen from "./pages/settings/fontscreen";
import $ from 'jquery';
export const App = () => {
  const { currentColor, setCurrentColor, currentMode, setCurrentMode, activeMenu, themeSettings, setThemeSettings } = useStateContext()
  const { enqueueSnackbar } = useSnackbar();

  
  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode')
    const currentThemeMode = localStorage.getItem('themeMode')
    if( currentThemeColor, currentThemeMode) {
      setCurrentColor(currentThemeColor)
      setCurrentMode(currentThemeMode)
    }
  }, [])
  
  
  
  const [loggedIn, setloggedIn] = useState();
  const [loggedSetting, setloggedSetting] = useState([]);
  const [isSidebar, setIsSidebar] = useState(true);
  const [dataFromChild, setDataFromChild] = useState('');
  const [loading, setLoading] = useState(false);
  const [post, setPost] = useState([]);
  const [sideBardata, setSideBardata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [statusKitchen, setStatusKitchen] = useState(0);
  const [sideMenuCls, setSideMenuCls] = useState();
  const [sideActiveMenuCls, setSideActiveMenuCls] = useState();
  const [isCollapsedMenu, setIsCollapsedMenu] = useState(false);
  const currentTagsVars = ReactSession.get('currentTags');
  const currentFontVal = ReactSession.get('currentFont');
  const isPrintGroup = ReactSession.get('isPrintGroup');
  const [activeIndex, setActiveIndex] = useState(0);
  const [newArrivalsCount, setNewArrivalsCount] = useState(0);
  const [newDelayedCount, setNewDelayedCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [clickTimer, setClickTimer] = useState(null);
  const [totalRecords, setTotalRecords] = useState(null);
  const [modalOpenRead, setModalOpenRead] = useState(false);
  
  
  const [pauseAudio, setPauseAudio] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playingAudio, setPlayingAudio] = useState(null);
  const [screenName, setScreenName] = useState(null);
  
  const audioRef = useRef(null);
  const buttonRef = useRef(null);
  const [audioPath, setAudioPath] = useState();
  const [audioValue, setAudioValue] = useState();
  let PageSize = 8;
 
 
  
   useEffect(() => {
    const storedLoggedInStatus = ReactSession.get('authToken');
    const userSettingVars = ReactSession.get('userSetting');

    if (Array.isArray(userSettingVars) && userSettingVars.length > 0) {
      //console.log(userSetting[0]) // Will not run because arr is undefined
  
      var getPath = ReactSession.get("assetPath");
      var audioNumber = userSettingVars[0].kds_new_order_audio;
      var getSoundName = ReactSession.get("audioNotification");
      var getNameAudio = getSoundName.filter(
        (i) =>  {if(i.id === audioNumber) return i.id; }
  
      );
      if (getNameAudio && getNameAudio.length > 0) {
      setAudioPath( getPath+''+getNameAudio[0].name)
      const audio = new Audio(audioPath);
      setAudioValue(audio)
      }
      
      
       
     }
    if (storedLoggedInStatus) {
      setloggedIn(true);
      setloggedSetting(userSettingVars);
    }
    callApi();
   
    sideBarApi();
    
   }, [loggedIn , isCollapsedMenu]);

   useEffect(() => {
    callApi();
    sideBarApi();
  }, [statusKitchen]); // Empty dependency array ensures useEffect runs only once after initial render
  function callbackFunction(childData) {
    setloggedIn(childData);
    callApi();
    sideBarApi();

  }

  const sideBarApi = async () => {
    const getToken = ReactSession.get("authToken")
    axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`;
    const configA = {
      headers: { Authorization: `Bearer ${getToken}` }
    };
  
    const url = config().apiUrl + "kitchen-side-menu";
    try {
      if(currentTagsVars !== undefined){
          let response = await axios.get(url, {
            params: {
              "tags": currentTagsVars
                // Add more parameters as needed
            },
            configA
        }); 
        setSideBardata(response.data.data) 
           
      }
           
      } catch (error) {
        // Handle error
        if (error.response && error.response.status === 401) {
          // Reload the page if a 401 status is received
          ReactSession.set("currentTags", null);
          ReactSession.set("kdsName", null);
          ReactSession.set("authToken", null);
          ReactSession.set("userData", null);
          ReactSession.set("kdsName", null);
          ReactSession.set("userSetting", null);
          ReactSession.set("alltags", null);
          window.location.reload();
        } else {
          console.error(error);
        }
      } finally {
        // Set loading state to false regardless of success or failure
      }
  };

  const callApi = async () => {
    setLoading(true);
    const getToken = ReactSession.get("authToken")
    axios.defaults.headers.common['Authorization'] = `Bearer ${getToken}`;
      // Set headers
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken}`
      };
    try {
      const url = config().apiUrl + "kitchen-orders";
        let response;
          if (statusKitchen === 0 || statusKitchen === 3 || statusKitchen === 4) {
            if(currentTagsVars !== undefined){
            response = await axios.post(url,{
              "page": 0,
              "tags": currentTagsVars
            }, { headers });
           }
          } else if (statusKitchen === 1) {
            if(currentTagsVars !== undefined){
            response = await axios.post(url,  {
              "page": 0,
              "tags": currentTagsVars,
              "status": 5,
              "last_recall" : false
            },{ headers });
            }
          }

          else if (statusKitchen === 2) {
            response = await axios.post(url, {
              "page": 0,
              "tags": currentTagsVars,
              "status": 5,
              "last_recall" : true
            },{ headers });
          }
         
            if(loggedSetting.length){
              setLoading(false)
              const currentTime = new Date();
              
              let defalutcolor = "";
             var newOrderColor  = loggedSetting[0].kds_new_order_color;
             var forlatorderTime = loggedSetting[0].kds_default_preparation_time ;
             var forNeutralTime = loggedSetting[0].kds_default_preparation_time/2;
             var  neutralOrdercolor =loggedSetting[0].kds_neutral_order_color;
             var  lateOrdercolor =loggedSetting[0].kds_late_order_color;
             var kdsnewordercolortime =loggedSetting[0].kds_new_order_color_time;
             var kdsneutralordercolortime =loggedSetting[0].kds_neutral_order_color_time;
             var kdslateordercolortime =loggedSetting[0].kds_late_order_color_time
             var lastDockets = response.data.data.length  ;
             var i = 1 , lastNumber = 9;

             
             
              if(response.data.data.length === 0 && statusKitchen === 2){
                setStatusKitchen(0)
                
                let timerInterval;
                Swal.fire({
                title: "No dockets Available",
                 html: "<div className='hide'></div> <b className='hide'></b></div>",
                timer: 3000,
                position: "center",
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                  timerInterval = setInterval(() => {
                    timer.textContent = ``;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log("I was closed by the timer");
                }
              });
                
                
              }
             if(response.data.data.length > 0){
              
              const modifiedData = response.data.data.map(item => {
              
              const timeDiff = new Date(item.updated_at.toString());

              const timeDiffNew = new Date(item.updated_at.toString());
              //const timeDiffNeutral = new Date(item.updated_at.toString());
              //const timeDiffLate = new Date(item.updated_at.toString());
            
              timeDiffNew.setMinutes(timeDiffNew.getMinutes() +  parseInt(kdsnewordercolortime, 10));
              const originalNew = new Date(timeDiffNew);
              const formattedDateTimeNew = `${originalNew.getFullYear()}-${(originalNew.getMonth() + 1).toString().padStart(2, '0')}-${originalNew.getDate().toString().padStart(2, '0')} ${originalNew.getHours().toString().padStart(2, '0')}:${originalNew.getMinutes().toString().padStart(2, '0')}:${originalNew.getSeconds().toString().padStart(2, '0')}`;
              
              
              const timeDiffNeutral = new Date(formattedDateTimeNew);
              timeDiffNeutral.setMinutes(timeDiffNeutral.getMinutes() +  parseInt(kdsneutralordercolortime, 10));
              const originalNeutral = new Date(timeDiffNeutral);
              const formattedDateTimeNeutral = `${originalNeutral.getFullYear()}-${(originalNeutral.getMonth() + 1).toString().padStart(2, '0')}-${originalNeutral.getDate().toString().padStart(2, '0')} ${originalNeutral.getHours().toString().padStart(2, '0')}:${originalNeutral.getMinutes().toString().padStart(2, '0')}:${originalNeutral.getSeconds().toString().padStart(2, '0')}`;
            

              const timeDiffLate = new Date(formattedDateTimeNeutral);

              timeDiffLate.setMinutes(timeDiffLate.getMinutes() +  parseInt(kdslateordercolortime, 10));
              const originalLate = new Date(timeDiffLate);
              const formattedDateTimeLate = `${originalLate.getFullYear()}-${(originalLate.getMonth() + 1).toString().padStart(2, '0')}-${originalLate.getDate().toString().padStart(2, '0')} ${originalLate.getHours().toString().padStart(2, '0')}:${originalLate.getMinutes().toString().padStart(2, '0')}:${originalLate.getSeconds().toString().padStart(2, '0')}`;
          
              const timestamp = new Date(item.updated_at.toString());

              const formattedTime = `${timestamp.getHours()}:${timestamp.getMinutes()}`;
              // Add 20 minutes to the timestamp
              timestamp.setMinutes(timestamp.getMinutes() +  parseInt(forlatorderTime, 10));


              // Parse the original date string into a Date object
          const originalDate = new Date(timestamp);

          // Format the Date object into the desired format
          const formattedDate = `${originalDate.getFullYear()}-${(originalDate.getMonth() + 1).toString().padStart(2, '0')}-${originalDate.getDate().toString().padStart(2, '0')} ${originalDate.getHours().toString().padStart(2, '0')}:${originalDate.getMinutes().toString().padStart(2, '0')}:${originalDate.getSeconds().toString().padStart(2, '0')}`;

              timeDiff.setMinutes(timeDiff.getMinutes() + parseInt(forNeutralTime, 10));

              // Format the resulting date
            
              const originalDateN = new Date(timeDiff);

              // Format the Date object into the desired format
            const formattedDateN = `${originalDateN.getFullYear()}-${(originalDateN.getMonth() + 1).toString().padStart(2, '0')}-${originalDateN.getDate().toString().padStart(2, '0')} ${originalDateN.getHours().toString().padStart(2, '0')}:${originalDateN.getMinutes().toString().padStart(2, '0')}:${originalDateN.getSeconds().toString().padStart(2, '0')}`;
              
              defalutcolor = lateOrdercolor ; // Add the color key to the item and set its value
               let lateOrder;
               let priorityOrder;
                if (currentTime <= originalNew) {
                     defalutcolor = newOrderColor;// Add the color key to the item and set its value
                     
                  } 
                  else if (currentTime <= originalNeutral) {
                    defalutcolor = neutralOrdercolor; // Add the color key to the item and set its value
                    
                  } else if (currentTime <= originalLate) {
                    defalutcolor = lateOrdercolor ;
                    
                  }
                 var lastDoc = lastDockets == lastNumber ? true : false 
                 
                 if (i % 9 === 0) {
                  lastNumber =  lastNumber + 8;
                 }
                 i = i + 1;
                 const currentTimeNow = new Date();
                 
                 if (currentTime <= originalNew) {

                  lateOrder = false ;
                  priorityOrder = true;

                 }else if (currentTime <= originalNeutral) {
                  lateOrder = false;
                  priorityOrder = false;
                 } else if (currentTime > originalLate) {
                  lateOrder = true;
                  priorityOrder = false;
                  // console.log(currentTime)
                  // console.log(originalLate)
                  // console.log("..................................")
                 }
                
                      return {
                ...item,
                tableitem: item.tableitem.map((items, index) => {
                  let checkStatus = false;
                  if(items.kitchen_status==6){
                    checkStatus = true;
                  }else{
                    checkStatus = false;
                  }
                    // Modify the kitchen_status of the first item
                    return {
                      ...items,
                      completed: checkStatus // Set to 1 for ready, or any other desired value
                    };
                 
                }),

                priorityOrder :priorityOrder,
                lateOrder : lateOrder,
                forlatorderTime: formattedDate,
                forNeutralTime:formattedDateN,
                neutralOrdercolor:neutralOrdercolor,
                lateOrdercolor:lateOrdercolor,
                newOrderColor:newOrderColor,
                defalutcolor :defalutcolor,
                formattedTime : formattedTime,
                formattedDateTimeNew: formattedDateTimeNew,
                formattedDateTimeNeutral:formattedDateTimeNeutral,
                formattedDateTimeLate:formattedDateTimeLate,
                lastDocket : lastDoc,
                
                
              };
                
              
            
              })
              if (statusKitchen !== 1){
                setNewDelayedCount(modifiedData.filter(item => item.lateOrder === true).length)
              }else if(statusKitchen === 1){
                setNewDelayedCount(0)
              }
              
              setNewArrivalsCount(modifiedData.filter(item => item.priorityOrder === true).length)
                
                if(statusKitchen===3){
                  setPost([]);
                  setPost(modifiedData.filter(item => item.lateOrder === true));
                  
                }else  if(statusKitchen===4){
                  setPost([]);
                  setPost(modifiedData.filter(item => item.priorityOrder === true));
                }
                else{
                  setPost([]);
                  setPost(modifiedData);
                }
                
                 
                
              
             }else{
              
              
              setPost([]);
              
              setTimeout(() => {
                
              }, 5000);

             }

              // Update data state with fetched data
            
            }  
            
                
      } catch (error) {
        // Handle error
        if (error.response && error.response.status === 401) {
          // Reload the page if a 401 status is received
          ReactSession.set("currentTags", null);
          ReactSession.set("kdsName", null);
          ReactSession.set("authToken", null);
          ReactSession.set("userData", null);
          ReactSession.set("kdsName", null);
          ReactSession.set("userSetting", null);
          ReactSession.set("alltags", null);
          window.location.reload();
        } else {
          //console.error(error);
        }
      } finally {
        setLoading(false); // Set loading state to false regardless of success or failure
      }
  };
  
    
    const currentTableDataMemo = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
  
      setTotalRecords(post.length)
    
    
    if (Array.isArray(post) && post.length > 0) { // Check if post is an array
      const now = new Date();
      return post.slice(firstPageIndex, lastPageIndex);
    } 
  }, [post, currentPage, PageSize]);

  const currentTableData = (currentTableDataMemo == undefined) ? [] : currentTableDataMemo ;
  const handleChildData = (data) => {
    setDataFromChild(data);
  };

  const handlePageNumber = (data) => {
    setCurrentPage(data);
  };
  
  const toggleItemStatus = async (itemId,kitchenstatus,fosStatus) => {
    
    const modifiedData = post.map(item => {
      const updatedTableitem = item.tableitem.map(tableItem => {
        if (tableItem.id === itemId) {
          return {
            ...tableItem,
            completed: !tableItem.completed,
            kitchen_status: kitchenstatus === 0 ? 6 : 0,
          };
        }
        return tableItem;
      });
      return {
        ...item,
        tableitem: updatedTableitem,
      };
    });
      
    setPost(modifiedData);

    

    var kitchenStatus = kitchenstatus == '0' ? '6' : '0'
    const url = config().apiUrl + "kitchen-status-update";
    
    try {
       const response = await axios.post(url, {
          menu_id: itemId,   
          status:kitchenStatus,
          is_online:fosStatus == 0? 0 : 1,
      });
       //setPost(updatedItems);
      // callApi()
      sideBarApi();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle 401 Unauthorized error
        ReactSession.set("currentTags", null);
        ReactSession.set("kdsName", null);
        ReactSession.set("authToken", null);
        ReactSession.set("userData", null);
        ReactSession.set("kdsName", null);
        ReactSession.set("userSetting", null);
        ReactSession.set("alltags", null);
        window.location.reload(); // Reload the page
      } else {
        // Handle other errors
        console.error(error);
      }
    }
  };


  useEffect(() => {
    return () => {
      clearTimeout(clickTimer);
    };
  }, [clickTimer]);
   


  // Define the function to be passed
  const sideMenuClick = (data) => {

    if (selectedRow === data) {
      
      setSideMenuCls(null)
      setSideActiveMenuCls(null)
     
      // If the same row is clicked again within the time interval, deselect it
      clearTimeout(clickTimer);
      setSelectedRow(null);
    } else {

      setSideMenuCls("")
    setSideMenuCls(data)
    setSideActiveMenuCls("")
    setSideActiveMenuCls(data)
      // If a different row is clicked, or the same row is clicked after the time interval
      clearTimeout(clickTimer);
      setSelectedRow(data);
      setClickTimer(
        setTimeout(() => {
          setClickTimer(null);
        }, 300) // Adjust the time interval (in milliseconds) as needed
      );
    }

    
  };

  const collapsedClick = () => {
     if(isCollapsedMenu == false){
      setIsCollapsedMenu(true)
     }else if(isCollapsedMenu == true){
      setIsCollapsedMenu(false)
     }
    
  };

  // Define the function to be passed
  const docketBump = (id,status,LastDocketStatus,AllItemIds,isFos) => {
    docketBumpStatus(id,status,LastDocketStatus,AllItemIds,isFos)
    // if(status==0){
    //   Swal.fire({
    //     title: 'Swal.fireAre you sure?',
    //     text: 'You will not be able to recover this data!',
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: 'Yes, bump it!',
    //     cancelButtonText: 'No, cancel!',
    //     reverseButtons: true
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       Swal.fire(
    //         'Bumped!',
    //         'Your data has been bumped.',
    //         'success'
    //       );
    //       docketBumpStatus(id,status)
    //       // Perform your action here if confirmed
    //     } else if (result.dismiss === Swal.DismissReason.cancel) {
    //       Swal.fire(
    //         'Cancelled',
    //         'Your data is safe :)',
    //         'error'
    //       );
    //     }
    //   });
      
    // }else if(status==5){
    //   Swal.fire({
    //     title: 'Are you sure?',
    //     text: 'You will be revert your action!',
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: 'Undo!',
    //     cancelButtonText: 'No, cancel!',
    //     reverseButtons: true
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       Swal.fire(
    //         'Undo Bumped!',
    //         'Revert your action successfully.',
    //         'success'
    //       );
    //       docketBumpStatus(id,status)
    //       // Perform your action here if confirmed
    //     } else if (result.dismiss === Swal.DismissReason.cancel) {
    //       Swal.fire(
    //         'Cancelled',
    //         'Your data is safe :)',
    //         'error'
    //       );
    //     }
    //   });
    // }
    
  };

  const docketBumpStatus = async (itemId,status,LastDocketStatus,AllItemIds,isFos) => {
     
    var kitchenStatus = status == 5 ? 0 : 5 ;
    const url = config().apiUrl + "kitchen-docket-status-update";
    
    try {
       const response = await axios.post(url, {
          docket_id: itemId,   
          status:kitchenStatus,
          items_id:AllItemIds,
          is_online:isFos == 0 ? 0 : 1 
      });
       
      
       if(LastDocketStatus){
        // window.location.reload();
        handlePageNumber(1)
       }else{
        callApi();
        sideBarApi();
       }
    } catch (error) {
      //console.error('Error updating item status:', error);
    }
  };
   

   const handleStatusTabClick = (id) => {
   
   if(statusKitchen === 0){
        setStatusKitchen(1);
        setScreenName("Completed Dockets")
    } else if(statusKitchen === 1){
        setStatusKitchen(0);
        setScreenName("Home");
    }
    
   };

   const handleLastStatusTabClick = (id) => {
   
   if(statusKitchen === 0){
        setStatusKitchen(2);
        setScreenName("Recall Last");
    } else if(statusKitchen === 2){
        setStatusKitchen(0);
        setScreenName("Home");
    }
    

   }

   const handleDelayedTabClick = (id) => {
  
      if(statusKitchen === 0){
        setStatusKitchen(3);
        setScreenName("Delayed Orders")
    } else if(statusKitchen === 3){
      setScreenName("Home")
        setStatusKitchen(0);
    }

    
 
    
   }

   const handlePriorityTabClick = (id) => {
  
    if(statusKitchen === 0){
      setStatusKitchen(4);
      setScreenName("New Arrivals")
  } else if(statusKitchen === 4){
    setScreenName("Home")
      setStatusKitchen(0);
      
  }
  

 }
  
   const handleUpdateModal = (updatedData) => {
   
    setModalOpenRead(updatedData)
    if(updatedData === false){
      // Set up interval to fetch data every 5 minutes (adjust as needed)
      // const intervalId = setInterval(callApi, 9000);
      // const intervalSidebar = setInterval(sideBarApi,9000);
      callApi();
      sideBarApi();
      // Clean up the interval when the component unmounts
      return () => {
        // clearInterval(intervalId);
        // clearInterval(intervalSidebar);
        // console.log('Interval cleared');
      };
    }
   }

    // useEffect hook to fetch data initially and set up the interval
  useEffect(() => {
    // Fetch data initially
    
    if(modalOpenRead==false){
    // Set up interval to fetch data every 5 minutes (adjust as needed)
    const intervalId = setInterval(callApi, 9000);
    const intervalSidebar = setInterval(sideBarApi,9000);
    
    // Clean up the interval when the component unmounts
    return () => {
      
    clearInterval(intervalId);
     clearInterval(intervalSidebar);
      
      // console.log('Interval cleared');
    };
   
  }
  }, [post]); // Empty dependency arr//
   
  useEffect(() => {
    
    Swal.fire({
      title: 'Allow for Notification',
      position: 'center',
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `,
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `,
      },
      grow: false,  // Remove row growth
      width: '400px', // Fixed width
      padding: '2rem',
      showConfirmButton: true,
      confirmButtonText: 'OK',
      confirmButtonClass: 'swal2-confirm-large',
      customClass: {
        popup: 'swal2-square',
        confirmButton: 'swal2-confirm-large'
      },
      showCloseButton: true,
    })
   
  }, []); 
  
  const handlePlayPause = () => {
    console.log(audioPath)
    setPauseAudio(!pauseAudio)
    //location.reload();
     console.log(playingAudio)
    // console.log(pauseAudio)
    // console.log(isPlaying)
    if (playingAudio) {
      playingAudio.loop = false;
      playingAudio.pause();
      
    }
    // if (isPlaying === true) {
    //   audioRef.current.muted = true;
    //   audioRef.current.loop = false;
    //   audioRef.current.pause();

    //   audioRef.current.currentTime = 0;
      
      
    // } //else if (isPlaying === false) {
    //   const audio = new Audio(audioPath);
    //   audioRef.current = audio;
    //   if(audioRef.current)
    //     audioRef.current.muted = false
    //     audioRef.current.loop = true;
    //     audioRef.current.play()
    //   .catch(error => {
    //     console.error('Failed to play audio:', error);
    //   });
      
    //}
  // $('#player_1').trigger("play");
  
    setIsPlaying(!isPlaying);
  };

  const handleUpdateAudio = (updatedData) => {
      console.log(updatedData);
      setPlayingAudio(updatedData);
  }
  
 
 

  
  
  
  return (
    <div>
    {(loggedIn) ? (
      
    <div className={`${currentFontVal} ${currentMode === 'Dark' ? 'dark' : ''}`}>
      <SnackbarProvider anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        {(loggedIn && currentTableData !== undefined) ? (
        <BrowserRouter >
        {/* dark:bg-main-dark-bg */}
        <div className="relative flex dark:bg-secondary-dark-bg">
          <div className="fixed right-4 bottom-4 md:invisible lg:invisible sm:visible" style={{ zIndex:'' }}>
            <TooltipComponent
            content='Settings'
            position='Top'
            >
              <button
              type='button'
              onClick={() => setThemeSettings(true)}
              style={{ backgroundColor:currentColor, borderRadius:'50%'}}
              className='p-3 text-3xl text-white drop-shadow-xl hover:bg-light-gray'
              >
                <FiSettings/>
              </button>
            </TooltipComponent>
          </div>
          {activeMenu ? (
            <div className="fixed z-10 bg-white w-72 sidebar dark:bg-secondary-dark-bg">
              <Sidebar   isSidebar={isSidebar} collapsedClick={collapsedClick}  sideMenuClick={sideMenuClick} classNamesidebar="sidebar" sideBardata={sideBardata}  />
            </div>
          ) : (
            <div className="w-0 dark:bg-secondary-dark-bg">
              <Sidebar  isSidebar={isSidebar} collapsedClick={collapsedClick} SideMenuCls={sideActiveMenuCls}  sideMenuClick={sideMenuClick} classNamesidebar="sidebar" sideBardata={sideBardata} />
            </div>
          )}
          <div
          className={
            activeMenu
            ? 'dark:bg-secondary-dark-bg bg-main-bg min-h-screen md:ml-72 w-full'
            : 'bg-main-bg dark:bg-secondary-dark-bg w-full min-h-screen flex-2'
          }
          > 
            {/* className={
            activeMenu
            ? 'dark:bg-main-dark-bg bg-main-bg min-h-screen md:ml-72 w-full'
            : 'bg-main-bg dark:bg-main-dark-bg w-full min-h-screen flex-2'
          }<div className="fixed w-full md:static bg-main-bg dark:bg-main-dark-bg navbar">
              <Navbar/>
            </div> */}
            <div>
              {themeSettings  && <ThemeSettings handleStatusTabClick={handleStatusTabClick} statusKitchen={statusKitchen} handleLastStatusTabClick={handleLastStatusTabClick} setIsSidebar={setIsSidebar} onPagenumber={handlePageNumber} onData={dataFromChild} currentData={currentTableData} currentP={currentPage} post={post}
          pageSize={PageSize} />}

              <Routes>
                {/* dashboard */}
                {(loggedIn && currentTableData !== undefined) ? (
              <Route path="/" element={ (isPrintGroup == 1 && isPrintGroup !== undefined ) ? <OrderList screenName={screenName} handleUpdateAudio={handleUpdateAudio} pauseAudio={pauseAudio}  AudioPath={audioPath} handlePlayPause={handlePlayPause} buttonRef={buttonRef} audioRef={audioRef} handleUpdateModal={handleUpdateModal} docketBump={docketBump}  SideMenuCls={sideMenuCls} sideMenuClick={sideMenuClick} userSetting={loggedSetting} toggleItemStatus={toggleItemStatus} 
              onPagenumber={handlePageNumber} isCollapsedMenu={isCollapsedMenu} onData={handleChildData} currentTableData={currentTableData} currentP={currentPage} post={post}
              pageSize={PageSize}  /> : <OrderListWhitOutTags screenName={screenName} handleUpdateAudio={handleUpdateAudio} pauseAudio={pauseAudio} AudioPath={audioPath} buttonRef={buttonRef}  handlePlayPause={handlePlayPause} audioRef={audioRef} handleUpdateModal={handleUpdateModal} docketBump={docketBump}  SideMenuCls={sideMenuCls} sideMenuClick={sideMenuClick} userSetting={loggedSetting} toggleItemStatus={toggleItemStatus} 
              onPagenumber={handlePageNumber} isCollapsedMenu={isCollapsedMenu} onData={handleChildData} currentTableData={currentTableData} currentP={currentPage} post={post}
              pageSize={PageSize}  />} />
              ):(
              <Route path="/" element={<div className="flex items-center justify-center h-screen"><div className="p-6"><ReactLoading type="spinningBubbles" color="#fff" /></div></div>
                 } />
              
              
              )}
              <Route path='/delayed' element={<DelayedList docketBump={docketBump}  SideMenuCls={sideMenuCls} sideMenuClick={sideMenuClick} userSetting={loggedSetting} toggleItemStatus={toggleItemStatus} 
              onPagenumber={handlePageNumber} isCollapsedMenu={isCollapsedMenu} onData={handleChildData} currentTableData={currentTableData} currentP={currentPage} post={post}
              pageSize={PageSize}  />} />
                <Route path='/ecommerce' element={<OrderList docketBump={docketBump}  SideMenuCls={sideMenuCls} sideMenuClick={sideMenuClick} userSetting={loggedSetting} toggleItemStatus={toggleItemStatus} 
              onPagenumber={handlePageNumber} isCollapsedMenu={isCollapsedMenu} onData={handleChildData} currentTableData={currentTableData} currentP={currentPage} post={post}
              pageSize={PageSize} />} />

               <Route path='/settings' element={<SettingComponent activeIndexn={activeIndex} />} />
               <Route path='/tag-settings' element={<TagScreen activeIndexn={activeIndex} />} />
               <Route path='/font-settings' element={<FontScreen activeIndexn={activeIndex} />} />
               

             

              </Routes>
            </div>
           
          </div>
        </div>
        
        <Footer handlePlayPause={handlePlayPause}  newDelayedCount={newDelayedCount} newArrivalsCount={newArrivalsCount} TotalRecords={totalRecords} buttonRef={buttonRef} handleStatusTabClick={handleStatusTabClick} handleDelayedTabClick={handleDelayedTabClick} handlePriorityTabClick={handlePriorityTabClick} statusKitchen={statusKitchen} handleLastStatusTabClick={handleLastStatusTabClick} setIsSidebar={setIsSidebar} onPagenumber={handlePageNumber} onData={dataFromChild} currentData={currentTableData} currentP={currentPage} post={post}
          pageSize={PageSize} />
        
        
        </BrowserRouter>
        ):(<div className="flex items-center justify-center h-screen"><div className="p-6"><ReactLoading type="spinningBubbles" color="#111010" /></div></div>
        )}
      </SnackbarProvider>
    </div>
       ) : ( 
       
         <div className="app containern">
            <div className="main-content">
              <main className="content">
            <Form parentCallback={callbackFunction}  />
             </main>
           </div>
         </div>
         
         
        )
       }
       </div>
       
    
  )
}
