import { useState , useEffect } from "react";
import { useStateContext } from '../contexts/ContextProvider'
import { Box, IconButton, Typography } from "@mui/material";
import {  NavLink } from 'react-router-dom'
import { MdOutlineCancel } from 'react-icons/md'
import { SiShopware } from 'react-icons/si'
import { TooltipComponent } from '@syncfusion/ej2-react-popups'
import Grid from "@mui/material/Grid";
import { ReactSession } from 'react-client-session';
import { withStyles } from "@material-ui/styles";
import {List,useTheme,Button,Tab,Tabs} from "@mui/material";
import { Link,useLocation } from "react-router-dom";
import UndoIcon from '@mui/icons-material/Undo';
import SettingComponent from "../pages/settings";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import TagScreen from "../pages/settings/tagscreen";
import FontScreen from "../pages/settings/fontscreen";


const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme();
    const location = useLocation();
    const colors = tokens(theme.palette.mode);
    return (
      <MenuItem
        active={selected === title}
        style={{
          color: colors.grey[100],
        }}
        onClick={() => setSelected(title)}
        icon={icon}
      >
        <Typography>{title}</Typography>
        <Link to={to} />
      </MenuItem>
    );
  };

const Sidebar = (sideBardata,isSidebar,classNamesidebar,sideMenuClick, SideMenuCls , collapsedClick) => {
    const  { currentColor, setActiveMenu, activeMenu, screenSize } = useStateContext()
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selected, setSelected] = useState("Dashboard");
    const currentUserNameVars = ReactSession.get('kdsName');
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeMenuCls, setActiveMenuCls] = useState();
    const location = useLocation();
    const [selectedRow, setSelectedRow] = useState(null);
    const [clickTimer, setClickTimer] = useState(null);
    const handleChangesn = (_, activeIndex) => setActiveIndex(activeIndex);
    
    useEffect(() => {
      return () => {
        clearTimeout(clickTimer);
      };
    }, [clickTimer]);
    const handleCloseSidebar = () => {
        if (activeMenu !== undefined && screenSize <= 900){
            setActiveMenu(false)
        }
    }

    const menuClick = (data) => {
      if (selectedRow === data) {
        // If the same row is clicked again within the time interval, deselect it
        clearTimeout(clickTimer);
        setActiveMenuCls(null)
        setSelectedRow(null);
      } else {
        // If a different row is clicked, or the same row is clicked after the time interval
        clearTimeout(clickTimer);
        setSelectedRow(data);
        setActiveMenuCls("")
      setActiveMenuCls(data)
        setClickTimer(
          setTimeout(() => {
            setClickTimer(null);
          }, 300) // Adjust the time interval (in milliseconds) as needed
        );
      }
      
    };

    useEffect(() => {
    }, [activeIndex])

    useEffect(() => {
      if (location.pathname === '/settings') {
        setActiveMenu(true)
      }
    }, [location.pathname]);

    const activeLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-white text-md m-2'
    const normalLink =  'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2'
     
    return (
      <>
    <div className='h-screen pb-10 overflow-auto md:overflow-hidden md:hover:overflow-auto'>
        {activeMenu && (
            <>
            <div className="flex items-center justify-between">
                <Link to='/' onClick={handleCloseSidebar} className='flex items-center gap-3 mt-4 ml-3 text-xl font-extrabold tracking-tight dark:text-white text-slate-900'>
                    <SiShopware/> <span>{currentUserNameVars}</span>
                </Link>
                <TooltipComponent content='menu' postion='bottonCenter'>
                    <button
                    type='button'
                    onClick={() => setActiveMenu(!activeMenu)}
                    style={{color:currentColor}}
                    className='block p-3 mt-4 text-xl rounded-full hover:bg-light-gray md:hidden'
                    >

                        <MdOutlineCancel/>
                    </button>
                </TooltipComponent>
                
            </div>
            <div className="mt-10">
            {location.pathname === "/" || location.pathname === "/delayed" ? (
               <div className="menucls">
                   {sideBardata && sideBardata.sideBardata.map((item, index) => {
                    return (
                      <div key={index}>
                      <div id="sidemenucls" > {/* added key attribute */}
                        
                          {item.name}
                      </div>
                        {/* Added a fragment to wrap the mapped elements */}
                        {item.menu.map((j, index) => (
                          
                            <div id="headcls" className={` ${j.id == activeMenuCls ? 'sideMenuBarClsActive' : ''}`} key={index} onClick={() => { sideBardata.sideMenuClick(j.id); menuClick(j.id) }}>
                              <div className="col collist colsidebar"  title={j.name}  id={j.id} >
                                
                               {j.name} 
                               
                              </div>
                              {(j.is_qr == 1) && (<QrCodeScannerIcon style={{ color:"#ffff" }} />)}
                              <div className="col collist colsidebar" >
                                
                                  {j.count}
                                 
                              </div>
                              
                            </div>
                          
                        ))}

                        
                      </div>
                    );
                  })}
                   

               </div>
            ):
            
                          (<VerticalTabs value={activeIndex} onChange={handleChangesn}>
                            <Link className="linkCls" to='/settings' ><MyTab  className="tabcls" label="Kitchen Display Name"/></Link>
                            <Link className="linkCls" to='/tag-settings' ><MyTab  className="tabcls" label="Tags Settings" /></Link>
                            <Link className="linkCls" to='/font-settings' ><MyTab className="tabcls" label="Font Settings" /></Link>
                            
                          
                            
                            

                            
                          </VerticalTabs>)}
                          {activeIndex === 0  }
                          {activeIndex === 1  }
            </div>
            </>
        )}
    </div>
    </>
  )
};

const VerticalTabs = withStyles((theme) => ({
  flexContainer: {
    flexDirection: "column"
  },
  indicator: {
    display: "none"
  }
}))(Tabs);

const MyTab = withStyles((theme) => ({
  root: {
    backgroundColor: "#ccc",
    // borderRadius: theme.shape.borderRadius
  },
  wrapper: {
    backgroundColor: "#ddd",
    // padding: theme.spacing(2),
    // borderRadius: theme.shape.borderRadius
  },
  selected: {
    color: "tomato",
    borderBottom: "2px solid tomato"
  }
}))(Tab);

const TabContainer = (props) => {
  return (
    <Typography component="div" style={{ padding: 24 }}>
      {props.children}
    </Typography>
  );
};

export default Sidebar