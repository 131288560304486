export default function validate(values) {
  let errors = {};
   console.log(values)
  if (!values.pin) {
    errors.pin = "Pin is required";
  }else if (values.pin == 38946381){
    errors.pin = "Incorrect PIN";
  }
  // if (!values.userName) {
  //   errors.userName = "Kitchen Display Name is required";
  // }
  
  return errors;
}
