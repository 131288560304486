import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
import './pagination.css';
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    //return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
    
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };
  let lastPage = paginationRange[paginationRange.length - 1];
  
  if(totalCount === 0){
    return(
      <div></div>
    );
  }
  if(currentPage === 0 || paginationRange.length < 2){
    return (
      <ul
        className={classnames('pagination-container', { [className]: className })}
      >
        <li
          className={classnames('pagination-item', {
            disabled: currentPage === 1
          })}
          onClick={onPrevious}
        >   <ArrowBackIosNewSharpIcon />
          
        </li>
        {paginationRange.map(pageNumber => {
          if (pageNumber === DOTS) {
            return <li className="pagination-item dots">&#8230;</li>;
          }
          if(pageNumber === currentPage){
          return (
            
            <li
              className={classnames('pagination-item', {
                selected: pageNumber === currentPage
              })}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber  }/
              {lastPage } 
            </li>
           
          );
          }
        })}
        <li
          className={classnames('pagination-item', {
            disabled: currentPage === lastPage
          })}
          onClick={onNext}
        >
        <ArrowForwardIosSharpIcon />
          
        </li>
      </ul>
    );
  }else{
    return (
      <ul
        className={classnames('pagination-container', { [className]: className })}
      >
        <li
          className={classnames('pagination-item', {
            disabled: currentPage === 1
          })}
          onClick={onPrevious}
        >   <ArrowBackIosNewSharpIcon />
          
        </li>
        {paginationRange.map(pageNumber => {
          if (pageNumber === DOTS) {
            return <li className="pagination-item dots">&#8230;</li>;
          }
          if(pageNumber === currentPage){
          return (
            
            <li
              className={classnames('pagination-item', {
                selected: pageNumber === currentPage
              })}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber  }/
              {lastPage } 
            </li>
           
          );
          }
        })}
        <li
          className={classnames('pagination-item', {
            disabled: currentPage === lastPage
          })}
          onClick={onNext}
        >
        <ArrowForwardIosSharpIcon />
          
        </li>
      </ul>
    );

  }
  
  
};

export default Pagination;