import { useState, useEffect } from "react";
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import config , { updateDomain } from '../../config';

const useForm = (callback, validate) => {
  const [values, setValues] = useState({});
  const [tagsScreen, setTagsScreen] = useState(false);
  const [tagsScreenValue, setTagsScreenValue] = useState([]);
  const [tagsScreenPreValue, setTagsScreenPreValue] = useState([]);
  const [errors, setErrors] = useState({});
  const [result, setResult] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  ReactSession.setStoreType("localStorage");

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
  }, [errors]);

  const handleSubmit = event => {
    if (event) event.preventDefault();
   
    setErrors(validate(values));
    let checkResp = loginApi(values.pin, values.domain)
    updateDomain(values.domain);
  
    // Now when we call getConfig(), it will use the updated domain
    const updatedConfig = config();
    console.log(updatedConfig.apiUrl);

    
  };

  const handleSubmitLast = event => {
    if (event) event.preventDefault();
    //console.log(validate(values))
    setErrors(validate(values));
    //let checkResp = loginApi(values.pin)
  };
  
  const loginApi = async (pin, domain) => {
    console.log(domain);
    const url = 'https://' + domain + '/admin/api/kds-login';
    try {
        const response = await axios.post(url, {
            pin: pin,
        });

        if (response.data.success == true) {
            setResult(response.data); // Update data state with fetched data

            var datavar = response.data.user;
            if (datavar !== undefined) {
                ReactSession.set("authToken", datavar.token || null);
                ReactSession.set("domain", domain);
                updateDomain(domain);
                ReactSession.set("userData", datavar || null);
                ReactSession.set("kdsName", datavar.name || null);
                ReactSession.set("userSetting", datavar.setting || []);
                ReactSession.set("alltags", datavar.tags || []);
                ReactSession.set("currentFont", datavar.kds_font_size || "default-size");
                ReactSession.set("isPrintGroup", datavar.setting?.[0]?.is_print_group || false);
                ReactSession.set("selectedTags", datavar.selected_tags || []);
                ReactSession.set("groupingTags", datavar.grouping_tags || []);
                ReactSession.set("kdsNotificationTimer", response.data.user.setting[0].kds_notification_timer);
                ReactSession.set("kdsNotification", response.data.user.setting[0].kds_notification);
                
                // Audio settings
                ReactSession.set("kdsNewOrderAudio", datavar.setting?.[0]?.kds_new_order_audio || "default-audio");
                ReactSession.set("kdsLateOrderAudio", datavar.setting?.[0]?.kds_late_order_audio || "default-audio");
                ReactSession.set("kdsEmergencyOrderAudio", datavar.setting?.[0]?.kds_emergency_order_audio || "default-audio");
                ReactSession.set("kdsCancelOrderAudio", datavar.setting?.[0]?.kds_cancel_order_audio || "default-audio");
                
                // Audio Path
                ReactSession.set("audioNotification", datavar.audio_notification?.audios || []);
                ReactSession.set("assetPath", datavar.audio_notification?.asset_path || "");

                // Visual Notification
                ReactSession.set("kdsNewOrder", response.data.user.setting[0].kds_new_order);
                ReactSession.set("kdsLateOrder", response.data.user.setting[0].kds_late_order);
                ReactSession.set("kdsEmergencyOrder", response.data.user.setting[0].kds_emergency_order);
                ReactSession.set("kdsCancelOrder", response.data.user.setting[0].kds_cancel_order);

                // Visual Notification type
                ReactSession.set("kdsTableMove", response.data.user.setting[0].kds_table_move);
                ReactSession.set("kdsItemVoid", response.data.user.setting[0].kds_item_void);
                ReactSession.set("kdsNewOrdeMessageType", response.data.user.setting[0].kds_new_order_message_type);

                // Config and button_config 
                ReactSession.set("fosStatus", response.data.config?.fos_status || "default-status");
                ReactSession.set("btnConfig", response.data.config?.btn_config || {});

                ReactSession.set("pusher_app_channel", datavar.pusher_config.pusher_app_channel || 0);
                ReactSession.set("pusher_app_key", datavar.pusher_config.pusher_app_key || 0);
                ReactSession.set("pusher_app_cluster", datavar.pusher_config.pusher_app_cluster || 0);
                ReactSession.set("event_notification", datavar.pusher_config.event_notification || 0);
                ReactSession.set("event_online_order", datavar.pusher_config.event_online_order || 0);
              

                setTagsScreenValue(datavar.tags || []);
                setTagsScreenPreValue(datavar.selected_tags || []);
                setIsSubmitting(false);
                setTagsScreen(true);

            } else {
                setErrors(validate(values));
                setIsSubmitting(false);
            }
        } else if (response.data.success == false) {
            const val = { pin: '38946381', userName: "test" };
            setErrors(validate(val));
            setIsSubmitting(false);
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Handle 401 Unauthorized error
            ReactSession.set("currentTags", null);
            ReactSession.set("kdsName", null);
            ReactSession.set("authToken", null);
            ReactSession.set("userData", null);
            ReactSession.set("userSetting", null);
            ReactSession.set("alltags", null);
            window.location.reload(); // Reload the page
        } else {
            // Handle other errors
            console.error(error);
        }
    }
};


  const handleChange = event => {
    event.persist();
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  };

  const handleChangeLast = event => {
    event.persist();
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  };

  return {
    handleChange,
    handleSubmit,
    values,
    tagsScreen,
    tagsScreenValue,
    tagsScreenPreValue,
    errors
  };
};

export default useForm;