export default function validate(values) {
  let errors = {};
   console.log(values)
   const domainRegex = /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/;
  if (!values.pin) {
    errors.pin = "Pin is required";
  }else if (values.pin == 38946381){
    errors.pin = "Incorrect PIN";
  }else  if (!values.domain) {
    errors.domain = "Domain is required";
  }
  else if (!domainRegex.test(values.domain)) {
   
    errors.domain='Invalid Domain';
  }
  // if (!values.userName) {
  //   errors.userName = "Kitchen Display Name is required";
  // }
  
  return errors;
}
