import { useState, useEffect } from "react";
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import config from '../../config';

const useForm = (callback, validate) => {
  const [values, setValues] = useState({});
  const [tagsScreen, setTagsScreen] = useState(false);
  const [tagsScreenValue, setTagsScreenValue] = useState([]);
  const [tagsScreenPreValue, setTagsScreenPreValue] = useState([]);
  const [errors, setErrors] = useState({});
  const [result, setResult] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  ReactSession.setStoreType("localStorage");

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
  }, [errors]);

  const handleSubmit = event => {
    if (event) event.preventDefault();
   
    setErrors(validate(values));
    let checkResp = loginApi(values.pin)

    
  };

  const handleSubmitLast = event => {
    if (event) event.preventDefault();
    //console.log(validate(values))
    setErrors(validate(values));
    //let checkResp = loginApi(values.pin)
  };
  const loginApi = async (pin) => {
    const url = config().apiUrl + "kds-login";
    try {
       const response = await axios.post(url, {
          pin: pin,   
      });
       if(response.data.success==true){
        setResult(response.data); // Update data state with fetched data
        var datavar = response.data.user;
        if( datavar != 'undefined'){
          ReactSession.set("authToken", response.data.user.token);
          ReactSession.set("userData", response.data.user);
          ReactSession.set("kdsName", response.data.user.name);
          ReactSession.set("userSetting", response.data.user.setting);
          ReactSession.set("alltags", response.data.user.tags);
          ReactSession.set("currentFont", response.data.user.kds_font_size);
          ReactSession.set("isPrintGroup", response.data.user.setting[0].is_print_group);
          ReactSession.set("selectedTags", response.data.user.selected_tags);
          ReactSession.set("groupingTags", response.data.user.grouping_tags);
          ReactSession.set("kdsNotificationTimer", response.data.user.kds_notification_timer);
          // audio settings

          ReactSession.set("kdsNewOrderAudio", response.data.user.setting[0].kds_new_order_audio);
          ReactSession.set("kdsLateOrderAudio", response.data.user.setting[0].kds_late_order_audio);
          ReactSession.set("kdsEmergencyOrderAudio", response.data.user.setting[0].kds_emergency_order_audio);
          ReactSession.set("audioNotification", response.data.user.audio_notification.audios);
          ReactSession.set("assetPath", response.data.user.audio_notification.asset_path)
         
          setTagsScreenValue(response.data.user.tags);
          setTagsScreenPreValue(response.data.user.selected_tags)
          setIsSubmitting(false);
          setTagsScreen(true);
          //window.location.reload();

          
        }else{
          setErrors(validate(values));
          setIsSubmitting(false);
         
        }
       }else if(response.data.success==false){
         const val = {pin: '38946381', userName:"test"}
        setErrors(validate(val));
        setIsSubmitting(false);
       }
        
        
      } catch (error) {
        if (error.response && error.response.status === 401) {
          ReactSession.set("currentTags", null);
          ReactSession.set("kdsName", null);
          ReactSession.set("authToken", null);
          ReactSession.set("userData", null);
          ReactSession.set("kdsName", null);
          ReactSession.set("userSetting", null);
          ReactSession.set("alltags", null);
          // Handle 401 Unauthorized error
          window.location.reload(); // Reload the page
        } else {
          // Handle other errors
          console.error(error);
        }
      } finally {
        
      }
  };

  const handleChange = event => {
    event.persist();
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  };

  const handleChangeLast = event => {
    event.persist();
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  };

  return {
    handleChange,
    handleSubmit,
    values,
    tagsScreen,
    tagsScreenValue,
    tagsScreenPreValue,
    errors
  };
};

export default useForm;