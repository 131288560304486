import { TooltipComponent } from '@syncfusion/ej2-react-popups'
import { Box, IconButton, useTheme } from "@mui/material";
import { useContext , useState, useEffect, useRef,scrollContainerRefs,useMemo } from "react";
import { BsCheck } from 'react-icons/bs'
import { MdOutlineCancel } from 'react-icons/md'
import { useStateContext } from '../contexts/ContextProvider'
import { themeColors } from '../data/dummy'
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import Pagination from '../pages/Pagination';
import { ReactSession } from 'react-client-session';
import { navigate } from '@reach/router';
import { Link } from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


const ThemeSettings = ({onData ,currentData, currentP, post ,pageSize,onPagenumber,handleStatusTabClick,handleLastStatusTabClick,statusKitchen}) => {
    const { setColor, setMode, currentMode, currentColor, setThemeSettings } = useStateContext()
    const logOutClick = () => {
        ReactSession.set("currentTags", null);
        ReactSession.set("kdsName", null);
        ReactSession.set("authToken", null);
        ReactSession.set("userData", null);
        ReactSession.set("kdsName", null);
        ReactSession.set("userSetting", null);
        ReactSession.set("alltags", null);
        ReactSession.set("domain", null);
        navigate('/');
        window.location.reload();
       
      };
    
      const settingClick = () => {
        navigate('/settings');
      };
  return (
    <div className='fixed top-0 right-0 z-20 w-screen bg-half-transparent nav-item'>
        <div className="float-right h-screen dark:text-gray-200 bg-white dark:bg-[#484B52] md:w-400 w-350">
            <div className="flex items-center justify-between p-4 ml-4">
                <p className="text-lg font-semibold">Settings</p>
                <button
                type='button'
                onClick={() => setThemeSettings(false)}
                style={{ color:'rgb(153, 171, 180)', borderRadius:'50%' }}
                className='p-3 text-2xl hover:drop-shadow-xl hover:bg-light-gray'
                >
                    <MdOutlineCancel/>
                </button>
            </div>
            <div className="flex-col p-4 ml-4 border-t-1 border-color">
                
                <div className="mt-4">
                <Tabs indicatorColor="secondary" textColor="inherit" orientation="vertical"
        variant="scrollable" aria-label="Vertical tabs">
        <Tab className="logout"  icon={<LogoutIcon />} onClick={logOutClick} />
          <Tab className="priority" label="Priority"  />
          <Tab className={`delayed ${onData}`} label="Delayed"  />
          {/* <Tab className="bumb" label="Bump"  /> */}
          <Tab className="cooking" label="Cooking"  />
          <Tab className="join"label="Join"  />
          {/*<Tab className="print"  icon={<ArrowBackIosNewSharpIcon />} />
           <Tab className="cooking" label="1"  />
          <Tab className="info" label="" icon={<ArrowForwardIosSharpIcon />}  />*/}
          
        <Pagination className="pagination-bar" currentPage={currentP} totalCount={ post ? post.length : 1  }
        pageSize={pageSize} onPageChange={page => onPagenumber(page)}/>
          <Tab className="recall"  label={statusKitchen=== 1 ? "Main" : "Recall"} disabled={statusKitchen=== 2 ? "disabled " : ""} onClick={handleStatusTabClick} />
        
          <Tab className="recall-last" label={statusKitchen=== 2 ? "Main" : "Recall Last"} disabled={statusKitchen=== 1 ? "disabled " : ""}   onClick={handleLastStatusTabClick} />
       
          
        </Tabs>
                </div>
            </div>
                {/* <div className="p-4 ml-4 border-t-1 border-color">
                    <p className='text-xl font-semibold'>Theme Colors</p>
                    <div className="flex gap-3">
                        {themeColors.map((item, index) => 
                        <TooltipComponent cssClass='my-tooltip' key={index} content={item.name} position='TopCenter'>
                            <div 
                            className="relative flex items-center gap-5 mt-2 cursor-pointer"
                            key={item.name}
                            >
                                <button
                                type='button'
                                className='w-10 h-10 rounded-full cursor-pointer'
                                style={{ backgroundColor: item.color }}
                                onClick={() => setColor(item.color)}
                                >
                                    <BsCheck className={`ml-2 text-2xl text-white ${item.color === currentColor ? 'block' : 'hidden'}`}/>
                                </button>
                            </div>
                        </TooltipComponent>
                        )}
                    </div>
                </div> */}
        </div>
    </div>
  )
}

export default ThemeSettings
